<template>
  <div>
    <CreativeManagementSection/>
    <AppFeaturesSection/>
    <FunctionalitySection class="relative fill-width"/>
    <GetStartedToday/>
  </div>
</template>

<script>
import CreativeManagementSection from "@/views/landings/ApplicationsLanding/CreativeManagementSection";
import AppFeaturesSection from "@/views/landings/ApplicationsLanding/AppFeaturesSection";
import FunctionalitySection from "@/components/landings/FunctionalitySection";
import GetStartedToday from "@/components/landings/GetStartedToday";

export default {
  name: "ApplicationsLanding",
  components: {
    CreativeManagementSection,
    AppFeaturesSection,
    FunctionalitySection,
    GetStartedToday,
  }
}
</script>

<style scoped lang="scss">
.functionality-section {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 284px;
    background-image: linear-gradient(180deg, #F4F4F4 0%, rgba(244, 244, 244, 0) 100%);
    @media #{map-get($display-breakpoints, 'xs-only')} {
      display: none;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    background-color: var(--v-gray-0-base);
  }
}
</style>
