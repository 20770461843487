<template>
  <div class="creative-management-section">
    <div class="creative-management-section-grid d-flex flex-column flex-sm-row align-center align-sm-stretch mx-auto">
      <div class="creative-management-video mr-sm-6 mr-md-12 mb-16 mb-sm-0 relative order-1 order-sm-0">
        <img :src="require('@/assets/images/landings/applicationLanding/creative-management.png')" alt="" class="relative fill-width">
      </div>

      <div class="creative-management-info flex-shrink-0 pt-sm-5 order-0 order-sm-1">
        <span v-if="$vuetify.breakpoint.smAndUp" class="text-title-3 text-uppercase font-weight-regular accent--text">
          Our features
        </span>

        <SectionTitle title="Centralized creative <br> management" tag="h1" class="mx-auto mt-sm-5 mb-7 mb-sm-9 text-center text-sm-left"/>

        <p class="mb-9 mb-sm-0 text-center text-sm-left">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget
        </p>

        <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex align-center mt-sm-8 mt-md-16">
          <img :src="require('@/assets/images/landings/arrow-right.svg')" alt="" class="arrow-right">
          <router-link :to="{name: 'Login'}" class="px-5 text-title-3 font-weight-black accent--text text-decoration-none">
            Learn More
          </router-link>
        </div>
      </div>

      <UiBtn v-if="$vuetify.breakpoint.xsOnly" color="accent" width="236" height="42" :to="{name: 'Login'}"
             class="order-2 mb-n5" style="z-index: 1">
        Get Started
      </UiBtn>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/landings/SectionTitle";
import UiBtn from "@/components/UI/UiBtn";
export default {
  name: "CreativeManagement",
  components: {
    SectionTitle,
    UiBtn,
  }
}
</script>

<style scoped lang="scss">
.creative-management-section {
  padding: 164px 20px 116px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 120px 20px 80px;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 38px 20px 0;
    background-color: var(--v-gray-100-base);
  }

  .creative-management-section-grid {
    max-width: 1300px;
  }

  .section-title {
    font-weight: 800;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 32px;
      line-height: 38px;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      max-width: 222px;
      color: var(--v-gray-0-base);
    }
  }

  .creative-management-video {
    max-width: 678px;

    &::before {
      content: "";
      position: absolute;
      top: -124px;
      bottom: -116px;
      right: 60px;
      width: 100vw;
      background: transparent url("~@/assets/images/landings/background-pattern.png") repeat 50% center;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        top: -84px;
        bottom: -80px;
      }

      @media #{map-get($display-breakpoints, 'xs-only')} {
        display: none;
      }
    }
  }

  .creative-management-info {
    p {
      max-width: 296px;
      font-size: 20px;
      line-height: 20px;
      color: var(--v-black-100-base);

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        font-size: 15px;
      }

      @media #{map-get($display-breakpoints, 'xs-only')} {
        max-width: 388px;
        color: var(--v-gray-0-base);
      }
    }

    .arrow-right {
      width: 218px;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        width: 168px;
      }
    }
  }
}
</style>
