<template>
  <div class="functionality-section">
    <SectionTitle title="All the functionality <br> you need" class="relative mb-13 mb-sm-11 pb-7 text-center"/>

    <div v-if="$vuetify.breakpoint.smAndUp" class="functionality-section-tabs d-flex flex-column align-center">
      <v-tabs
        v-model="currentTab"
        hide-slider
        height="40"
        mobile-breakpoint="280"
        background-color="gray-10"
        class="d-flex justify-center mb-5">
        <v-tab v-for="(tab, idx) in tabsItems" :key="idx" class="mx-1 text-capitalize text-captions-1 font-weight-bold">
          <component :is="tab.icon" class="mr-3" width="16"/>
          {{ tab.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab" class="d-inline-flex mx-auto gray-10 overflow-hidden">
        <v-tab-item v-for="(tab, idx) in tabsItems" :key="idx" eager :transition="false" class="relative">
          <img :src="require(`@/assets/images/landings/welcomeLanding/functionalitySection/${tab.bg_image}`)" alt=""
               class="fill-width fill-height object-cover">

          <div class="tab-info absolute">
            <img :src="require(`@/assets/images/landings/welcomeLanding/functionalitySection/${tab.line_image}`)" alt="" class="tab-line-img absolute">

            <div class="tab-info-content rounded gray-0">
              <h6 class="font-weight-semi-bold accent--text">{{ tab.title }}</h6>
              <p class="font-weight-regular gray-80--text">{{ tab.text }}</p>

              <div class="d-flex">
                <UiBtn
                  v-if="currentTab > 0"
                  text
                  height="auto"
                  class="mr-auto px-0 text-captions-1" @click="currentTab -= 1">
                  <IconArrowLeft width="14" class="mr-2 accent--text"/>
                  Back
                </UiBtn>
                <UiBtn
                  v-if="currentTab + 1 < tabsItems.length"
                  text
                  height="auto"
                  class="ml-auto px-0 text-captions-1"
                  @click="currentTab += 1">
                  Next
                  <IconArrowRight width="14" class="ml-2 accent--text"/>
                </UiBtn>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <v-expansion-panels
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="panel"
      accordion
      flat
      class="functionality-section-accordion rounded-0"
    >
      <v-expansion-panel v-for="(tab, idx) in tabsItems" :key="idx">
        <v-expansion-panel-header
          color="gray-10"
          class="py-5 px-6 text-captions-1 font-weight-semi-bold">
          <span class="d-flex align-center">
            <component :is="tab.icon" class="mr-2" width="16"/>
            {{ tab.title }}
          </span>
          <template v-slot:actions>
            <IconMinus v-if="idx === panel" width="18"/>
            <IconPlus v-else width="18"/>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-2 pb-7 px-6" color="gray-10" eager>
          <img :src="require(`@/assets/images/landings/welcomeLanding/functionalitySection/${tab.bg_image}`)" alt="" class="d-block mb-7 fill-width rounded-lg">
          <p class="mb-0 text-body gray-100--text">{{ tab.text }}</p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>
</template>

<script>
import SectionTitle from "@/components/landings/SectionTitle";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: "FunctionalitySection",
  components: {
    IconArrowLeft: () => import('@/components/icons/IconArrowLeft'),
    IconArrowRight: () => import('@/components/icons/IconArrowRight'),
    IconFileSolid: () => import('@/components/icons/IconFileSolid'),
    IconTasks: () => import('@/components/icons/IconTasks'),
    IconUserSolid: () => import('@/components/icons/IconUserSolid'),
    IconSlidersV: () => import('@/components/icons/IconSlidersV'),
    IconGlobe: () => import('@/components/icons/IconGlobe'),
    IconGrid: () => import('@/components/icons/IconGrid'),
    IconPlus: () => import('@/components/icons/IconPlus'),
    IconMinus: () => import('@/components/icons/IconMinus'),

    SectionTitle,
    UiBtn,
  },
  data() {
    return {
      panel: 0,
      currentTab: 0,
      tabsItems: [
        {
          title: "Files",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra platea congue in enim eu.",
          icon: "IconFileSolid",
          bg_image: "files.png",
          line_image: "files-line.svg",
          class: "files-tab",
        },
        {
          title: "Tasks",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra platea congue in enim eu.",
          icon: "IconTasks",
          bg_image: "tasks.png",
          line_image: "tasks-line.svg",
          class: "tasks-tab",
        },
        {
          title: "Spaces",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra platea congue in enim eu.",
          icon: "IconUserSolid",
          bg_image: "spaces.png",
          line_image: "spaces-line.svg",
          class: "spaces-tab",
        },
        {
          title: "Filtering",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra platea congue in enim eu.",
          icon: "IconSlidersV",
          bg_image: "filtering.png",
          line_image: "files-line.svg",
          class: "filtering-tab",
        },
        {
          title: "Global log",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra platea congue in enim eu.",
          icon: "IconGlobe",
          bg_image: "global-log.png",
          line_image: "tasks-line.svg",
          class: "global-log-tab",
        },
        {
          title: "Sections",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra platea congue in enim eu.",
          icon: "IconGrid",
          bg_image: "sections.png",
          line_image: "sections-line.svg",
          class: "sections-tab",
        },
      ]
    }
  },
}
</script>

<style scoped lang="scss">
.functionality-section {
  padding: 82px 40px 140px;
  background-color: var(--v-gray-10-base);

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 44px 20px 74px;
  }

  .section-title {
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 88px;
      height: 3px;
      background-color: var(--v-accent-base);
    }
  }

  @media screen and (max-width: 767px) {
    .functionality-section-tabs {
      display: none!important;
    }
  }

  @media screen and (min-width: 768px) {
    .functionality-section-accordion {
      display: none;
    }
  }

  .v-tabs {
    ::v-deep .v-slide-group__prev,
    ::v-deep .v-slide-group__next {
      display: none;
    }

    .v-tab {
      min-height: 40px;
      border: 1px solid var(--v-accent-base);
      border-radius: 3px;
      color: var(--v-gray-0-base);
      background-color: var(--v-accent-base);

      &:not(.v-tab--active) {
        border: 1px solid var(--v-gray-60-base);
        color: var(--v-accent-base);
        background-color: var(--v-gray-0-base);

        svg {
          color: var(--v-gray-60-base);
        }
      }

      svg {
        color: var(--v-gray-0-base);
      }
    }
  }

  .v-tabs-items {
    border-radius: 20px;

    .tab-info {
      width: 276px;
      z-index: 1;

      @media screen and (max-width: 1130px) {
        width: 230px;
      }
    }

    .tab-info-content {
      padding: 20px 30px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 134px #FFFFFF;

      @media screen and (max-width: 1130px) {
        padding: 12px 20px;
      }

      h6 {
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 23px;

        @media screen and (max-width: 1130px) {
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 8px;
        }
      }

      p {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 17px;

        @media screen and (max-width: 1130px) {
          margin-bottom: 16px;
          font-size: 12px;
          line-height: 13px;
        }
      }
    }

    .v-btn {
      &::before {
        display: none;
      }

      svg {
        margin-top: 1px;
      }
    }

    .v-window-item {
      width: 1050px;
      height: 682px;

      @media screen and (max-width: 1130px) {
        width: 688px;
        height: 456px;
      }

      &:nth-child(1) {
        .tab-info {
          top: 54.5%;
          left: 64%;
        }
        .tab-line-img {
          top: -78px;
          left: -20.6%;
        }

        @media screen and (max-width: 1130px) {
          .tab-info {
            top: 53.5%;
            left: 62.5%;
          }
          .tab-line-img {
            width: 40px;
            top: -48px;
            left: -15.7%;
          }
        }
      }

      &:nth-child(2) {
        .tab-info {
          top: 12.7%;
          left: 29.3%;
        }
        .tab-line-img {
          top: -30px;
          left: 98%;
        }

        @media screen and (max-width: 1130px) {
          .tab-info {
            top: 12.2%;
            left: 22.7%;
          }
          .tab-line-img {
            width: 80px;
            top: -17px;
            left: 98.3%;
          }
        }
      }

      &:nth-child(3) {
        .tab-info {
          top: 12.5%;
          left: 29.3%;
        }
        .tab-line-img {
          top: 27px;
          left: -69.2%;
        }

        @media screen and (max-width: 1130px) {
          .tab-info {
            top: 14%;
            left: 27.3%;
          }
          .tab-line-img {
            width: 120px;
            top: 19px;
            left: -50.9%;
          }
        }
      }

      &:nth-child(4) {
        .tab-info {
          top: 58.3%;
          left: 63%;
        }
        .tab-line-img {
          top: -79px;
          left: -20.5%;
        }

        @media screen and (max-width: 1130px) {
          .tab-info {
            top: 60.3%;
            left: 63%;
          }
          .tab-line-img {
            width: 48px;
            top: -62px;
            left: -18.8%;
          }
        }
      }

      &:nth-child(5) {
        .tab-info {
          top: 12.7%;
          left: 29.3%;
        }
        .tab-line-img {
          top: -29px;
          left: 98%;
        }

        @media screen and (max-width: 1130px) {
          .tab-info {
            top: 12.2%;
            left: 22.8%;
          }
          .tab-line-img {
            width: 80px;
            top: -17px;
            left: 98%;
          }
        }
      }

      &:nth-child(6) {
        .tab-info {
          top: 57.6%;
          left: 64%;
        }
        .tab-line-img {
          top: -280px;
          left: -121.8%;
        }

        @media screen and (max-width: 1130px) {
          .tab-info {
            top: 55.5%;
            left: 62%;
          }
          .tab-line-img {
            width: 220px;
            top: -178px;
            left: -94.1%;
          }
        }
      }
    }
  }

  .v-expansion-panels {
    border: 2px solid var(--v-gray-80-base);
    border-top: none;
  }

  .v-expansion-panel {
    border-top: 2px solid var(--v-gray-80-base);
  }

  .v-expansion-panel-header {
    min-height: auto;
  }

  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0;
  }

  ::v-deep .v-expansion-panel-header__icon svg {
    width: 18px;
    color: var(--v-gray-80-base) !important;
  }

}
</style>
