<template>
  <div class="app-features-section relative overflow-hidden">
    <div class="app-features-section-grid mx-auto">
      <div :class="['slide flex-column flex-sm-row align-sm-center mb-9 px-6 py-8', $vuetify.breakpoint.xsOnly || currentSlide === 0 ? 'd-flex' : 'd-none']">
        <div class="slide-info flex-shrink-0 order-1 order-sm-0">
          <SectionTitle title="Creative <br> Teams" class="mb-4 mb-sm-6"/>
          <p class="mb-0">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget
          </p>
        </div>
        <div class="slide-image mx-n11 mt-n13 mb-4">
          <img :src="require('@/assets/images/landings/applicationLanding/appFeaturesSection/creative-teams.png')" alt="" class="fill-width">
        </div>
      </div>

      <div :class="['slide flex-column flex-sm-row align-sm-center mb-9 px-6 py-8', $vuetify.breakpoint.xsOnly || currentSlide === 1 ? 'd-flex' : 'd-none']">
        <div class="slide-info flex-shrink-0 order-1 order-sm-0">
          <SectionTitle title="Development and <br> Production" class="mb-4 mb-sm-6"/>
          <p class="mb-0">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget
          </p>
        </div>
        <div class="slide-image">
          <img :src="require('@/assets/images/landings/applicationLanding/appFeaturesSection/development-and-production.png')" alt="" class="fill-width">
        </div>
      </div>

      <div :class="['slide flex-column flex-sm-row align-sm-center px-6 py-8', $vuetify.breakpoint.xsOnly || currentSlide === 2 ? 'd-flex' : 'd-none']">
        <div class="slide-info flex-shrink-0 order-1 order-sm-0">
          <SectionTitle title="Project <br> Managers" class="mb-4 mb-sm-6"/>
          <p class="mb-0">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget
          </p>
        </div>
        <div class="slide-image">
          <img :src="require('@/assets/images/landings/applicationLanding/appFeaturesSection/project-managers.png')" alt="" class="fill-width">
        </div>
      </div>
    </div>

    <div v-if="$vuetify.breakpoint.smAndUp" class="slides-controls absolute d-flex flex-column justify-space-between align-center relative">
      <button type="button" @click="currentSlide = 0" :class="['d-flex relative cursor-pointer pa-2 ma-n2', {'is-active': currentSlide === 0}]">
        <IconCircleFilled :class="currentSlide === 0 ? 'accent--text' : 'gray-45--text'"/>
      </button>
      <button type="button" @click="currentSlide = 1" :class="['d-flex relative cursor-pointer pa-2 ma-n2', {'is-active': currentSlide === 1}]">
        <IconCircleFilled :class="currentSlide === 1 ? 'accent--text' : 'gray-45--text'"/>
      </button>
      <button type="button" @click="currentSlide = 2" :class="['d-flex relative cursor-pointer pa-2 ma-n2', {'is-active': currentSlide === 2}]">
        <IconCircleFilled :class="currentSlide === 2 ? 'accent--text' : 'gray-45--text'"/>
      </button>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/landings/SectionTitle";

export default {
  name: "AppFeaturesSection",
  components: {
    IconCircleFilled: () => import('@/components/icons/IconCircleFilled'),

    SectionTitle,
  },
  data() {
    return {
      currentSlide: 0,
    }
  }
}
</script>

<style scoped lang="scss">
.app-features-section {
  padding: 90px 120px 120px;

  @media screen and (max-width: 1080px) {
    padding: 90px 86px 90px 38px;
  }

  @media #{map-get($display-breakpoints,  'xs-only')} {
    padding: 90px 20px 40px 20px;
  }

  .app-features-section-grid {
    max-width: 1204px;
  }

  .slide {
    height: 564px;

    @media screen and (max-width: 1080px) {
      height: 400px;
    }

    @media screen and (max-width: 767px) {
      height: 300px;
    }

    @media #{map-get($display-breakpoints,  'xs-only')} {
      height: auto;
      border-radius: 5px;

      &:nth-child(1) {
        background-color: var(--v-gray-10-base);
      }

      &:nth-child(2) {
        background-color: var(--v-gray-30-base);

        .slide-image {
          margin: -32px -74px 34px;
        }
      }

      &:nth-child(3) {
        background-color: var(--v-gray-60-base);

        .slide-info {
          .section-title, p {
            color: var(--v-gray-0-base);
          }
        }

        .slide-image {
          margin: -52px -82px 16px;
        }
      }
    }
  }

  .slide-info {
    margin-right: 106px;

    @media screen and (max-width: 1080px) {
      margin-right: 40px;
    }

    @media #{map-get($display-breakpoints,  'xs-only')} {
      margin-right: 0;
    }

    .section-title {
      @media screen and (max-width: 1080px) {
        font-size: 32px;
        line-height: 38px;
      }
    }

    p {
      font-size: 20px;
      line-height: 27px;
      max-width: 308px;
      color: var(--v-gray-100-base);

      @media screen and (max-width: 1080px) {
        max-width: 236px;
        font-size: 15px;
        line-height: 22px;
      }
    }
  }

  .slides-controls {
    top: 50%;
    transform: translateY(-50%);
    right: 60px;
    width: 24px;
    height: 250px;

    &::before {
      content: "";
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 50%;
      width: 2px;
      transform: translateX(-50%);
      background-color: var(--v-accent-base);
    }

    @media screen and (max-width: 1080px) {
      right: 30px;
      height: 180px;
    }

    button {
      svg {
        width: 10px;
      }

      &.is-active svg {
        width: 24px;

        @media screen and (max-width: 1080px) {
          width: 18px;
        }
      }
    }
  }
}
</style>
